import { createContext, useEffect, useState } from 'react'
import { useOrderStore } from '@/stores/OrderStore'
import { Tooltip, Button } from 'antd'
import GeneratePaymentDrawer from '../Components/GeneratePaymentDrawer'

const PaymentlinkBtn = ({ type, ...props }) => {
  const [openPaymentDrawer] = useOrderStore((state) => [state.openDrawer])

  return (
    <>
      <GeneratePaymentDrawer />
      <Tooltip title='支付链接'>
        {/* <Button type='text' onClick={() => openPaymentDrawer()} icon={<DollarOutlined className='text-orange-500' />} size={'middle'} /> */}

        {type === 'link' ? (
          <Button type={'link'} onClick={() => openPaymentDrawer()}>
            支付链接
          </Button>
        ) : (
          <Button type='text' onClick={() => openPaymentDrawer()} size={'middle'} className='px-1'>
            💲
          </Button>
        )}
      </Tooltip>
    </>
  )
}
export default PaymentlinkBtn
