import { createContext, useEffect, useState, useRef, useMemo } from 'react'
import { App, Button, Card, Empty, Flex, Select, Spin, Typography, Divider, Modal, List, Row, Col, Tag, Drawer, Input, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { InboxIcon, SendPlaneFillIcon, ExpandIcon } from '@/components/Icons'
import EmailDetailInline from '../Components/EmailDetailInline'
import { debounce, isEmpty } from '@/utils/commons'

const SupplierEmailDrawer = ({ list: otherEmailList, currentConversationID, opi_sn, oid, ...props }) => {
  const [open, setOpen] = useState(false)
  const [selectedEmail, setSelectedEmail] = useState({})
  const searchInputRef = useRef(null)
  const [dataSource, setDataSource] = useState([])
  useEffect(() => {
    setOpen(false);
    setDataSource(otherEmailList)
    // setSelectedEmail({ MAI_SN: -1000 });

    return () => {}
  }, [otherEmailList])
  const onClearSearch = () => {
    setDataSource(otherEmailList)
  }
  const handleSearch = (value) => {
    if (isEmpty(value)) onClearSearch()
    const res = otherEmailList.filter((ele) => `${ele.MAI_Subject}${ele.SenderReceiver}`.toLowerCase().includes(value.toLowerCase()))
    setDataSource(res)
  }
  return (
    <>
      <Button
        icon={<ExpandIcon />}
        type={'primary'}
        className='ml-2'
        ghost
        size='small'
        onClick={() => {
          setOpen(true)
        }}
      />
      <Drawer
        zIndex={3}
        mask={false}
        width={600}
        styles={{ header: {} }}
        title={`供应商邮件`}
        classNames={{ header: '!py-1 !px-2', body: '!p-1' }}
        placement='right'
        onClose={() => {
          setOpen(false)
        }}
        open={open}>
        <Input.Search
          className=''
          ref={searchInputRef}
          allowClear
          onClear={onClearSearch}
          onPressEnter={(e) => {
            handleSearch(e.target.value)
            return false
          }}
          onSearch={(v, e, { source }) => handleSearch(v)}
          placeholder={`输入: 标题/发件人, 回车搜索`}
        />
        <List
          dataSource={dataSource}
          pagination={{
            pageSize: 5,
            // showLessItems: true,
            showSizeChanger: false,
            size: 'small',
          }}
          renderItem={(emailItem) => (
            <List.Item
              className={`hover:bg-stone-50 cursor-pointer !py-1 ${selectedEmail.MAI_SN === emailItem.MAI_SN ? 'bg-blue-100 font-bold ' : ''}`}
              onClick={() => {
                const emailMsg = {
                  conversationid: currentConversationID,
                  order_opi: opi_sn,
                  coli_sn: oid,
                  id: emailItem.MAI_SN,
                  MAI_SN: emailItem.MAI_SN,
                  msgOrigin: {
                    from: '',
                    to: '',
                    id: emailItem.MAI_SN,
                    email: { mai_sn: emailItem.MAI_SN, subject: emailItem.MAI_Subject, id: emailItem.MAI_SN },
                  },
                }
                setSelectedEmail(emailMsg)
              }}>
              <Flex vertical={false} wrap={false} className='w-full'>
                <div className='flex-auto ml-auto min-w-40 line-clamp-1'>
                  {emailItem.Direction === '收' ? <InboxIcon className='text-indigo-500' /> : <SendPlaneFillIcon className='text-primary' />}
                  {/* <Tooltip title={emailItem.MAI_Subject}> */}
                  <Typography.Text >{emailItem.MAI_Subject}</Typography.Text>
                  {/* </Tooltip> */}
                </div>
                <div className='ml-1 max-w-40'>
                  <Typography.Text ellipsis={{ tooltip: emailItem.SenderReceiver }}>{emailItem.SenderReceiver}</Typography.Text>
                </div>
                <div className='ml-1 max-w-20'>
                  <Typography.Text ellipsis={{ tooltip: emailItem.MAI_SendDate }}>{dayjs(emailItem.MAI_SendDate).format('MM-DD HH:mm')}</Typography.Text>
                </div>
              </Flex>
            </List.Item>
          )}
        />
        <EmailDetailInline {...{ mailID: selectedEmail.MAI_SN, emailMsg: selectedEmail }} />
      </Drawer>
    </>
  )
}
export default SupplierEmailDrawer
