import { Radio, Row, Col, Tooltip, Flex, App, Typography, Form, Input, Button, InputNumber, Select, message } from 'antd'
import { useState, useEffect, useCallback } from 'react'
import HtmlPreview from './HtmlPreview'
import useAuthStore from '@/stores/AuthStore'
import useConversationStore from '@/stores/ConversationStore'
import { useOrderStore } from '@/stores/OrderStore'
import { InfoCircleOutlined } from '@ant-design/icons'
import useStyleStore from '@/stores/StyleStore';

function GeneratePayment() {

  const { notification } = App.useApp()
  const [messageApi, contextHolder] = message.useMessage()
  const [mobile, setMobile] = useStyleStore((state) => [state.mobile, state.setMobile]);

  const [generateForm] = Form.useForm()

  const [getPrimaryEmail, loginUser] = useAuthStore((s) => [s.getPrimaryEmail, s.loginUser])
  const [generatePayment, fetchOrderDetail] = useOrderStore((s) => [s.generatePayment, s.fetchOrderDetail])
  const currentOrder = useConversationStore((state) => state.currentConversation?.coli_sn || '')

  const [isHtmlLoading, setHtmlLoading] = useState(false)
  const [generatedObject, setGeneratedObject] = useState('')

  const handleGenerate = useCallback(() => {
    setHtmlLoading(true)
    generatePayment(generateForm.getFieldsValue())
      .then((result) => {
        setGeneratedObject(result)
      })
      .catch((ex) =>
        notification.error({
          message: 'Notification',
          description: ex.message,
          placement: 'top',
          duration: 4,
        }))
      .finally(() => setHtmlLoading(false))
  }, [])

  useEffect(() => {
    if (currentOrder) {
      fetchOrderDetail(currentOrder)
        .then((result) => {
          const orderNumber = result.orderDetail.order_no
          const travelAdvisor = loginUser.accountList.length > 0 ? loginUser.accountList[0].OPI_NameEN : ''
          generateForm.setFieldsValue({
            notifyEmail: getPrimaryEmail(),
            orderNumber: orderNumber,
            description: 'Tracking Code: ' + orderNumber + '\r\nTravel Advisor: ' + travelAdvisor + '\r\nContent: \r\n',
            langauge: 'US',
            orderType: '227001',
            currency: 'USD',
            amount: 1,
            userId: loginUser.userId,
          })
        })
        // .finally(() => setLoading(false))
        // .catch(() => {
        // })
    } else {
      generateForm.setFieldsValue({
        notifyEmail: getPrimaryEmail(),
        description: 'Tracking Code: \r\nTravel Advisor: \r\nContent: \r\n',
        langauge: 'US',
        orderType: '227001',
        currency: 'USD',
        amount: 1,
        userId: loginUser.userId,
      })
    }
  }, [currentOrder])

  return (
    <>
      <Flex gap={16} justify={mobile ? 'normal' : 'center'} vertical={mobile ? true : false}>
        <div className='basis-1/4'>
          <Form
            labelCol={{
              span: 4,
            }}
            layout={'horizontal'}
            form={generateForm}>
            <Form.Item name='userId' className='hidden'>
              <Input />
            </Form.Item>
            <Form.Item
              label='订单类型'
              name='orderType'
              rules={[
                {
                  required: true,
                  message: '订单类型必选',
                },
              ]}>
              <Radio.Group>
                <Radio.Button value='227001'>线路订单</Radio.Button>
                <Radio.Button value='227002'>商务订单</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label='语种'
              name='langauge'
              rules={[
                {
                  required: true,
                  message: '语种必选',
                },
              ]}>
              <Select
                options={[
                  { value: 'US', label: '英语' },
                  { value: 'de_de', label: '德语' },
                  { value: 'fr_fr', label: '法语' },
                  { value: 'es_es', label: '西语' },
                  { value: 'ja_JP', label: '日语' },
                  { value: 'ru_ru', label: '俄语' },
                  { value: 'it_it', label: '意大利语' },
                ]}
              />
            </Form.Item>
            <Form.Item
              label='订单号'
              name='orderNumber'
              rules={[
                {
                  required: true,
                  message: '订单号必填',
                },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              label='金额'
              name='amount'
              rules={[
                {
                  required: true,
                  message: '金额填',
                },
              ]}>
              <InputNumber
                addonBefore={
                  <Form.Item name='currency' noStyle>
                    <Select
                      style={{
                        width: 90,
                      }}
                      options={[
                        { value: 'USD', label: '美元' },
                        { value: 'CNY', label: '人民币' },
                        { value: 'EUR', label: '欧元' },
                        { value: 'JPY', label: '日元' },
                        { value: 'AUD', label: '澳元' },
                        { value: 'CAD', label: '加元' },
                        { value: 'HKD', label: '港币' },
                        { value: 'CHF', label: '瑞士法郎' },
                        { value: 'GBP', label: '英镑' },
                        { value: 'RUB', label: '卢布' },
                        { value: 'SGD', label: '新加坡元' },
                        { value: 'NZD', label: '新西兰元' },
                        { value: 'THB', label: '泰铢' },
                      ]}></Select>
                  </Form.Item>
                }
                min={1}
                className='w-full'
              />
            </Form.Item>
            <Form.Item label='描述' name='description'>
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item label='通知邮箱' name='notifyEmail'>
              <Input />
            </Form.Item>
          </Form>
          <Flex gap='middle' justify='center'>
            <Button type='primary' loading={isHtmlLoading} onClick={handleGenerate}>
              生成
            </Button>
          </Flex>
        </div>

        <div className='basis-1/4'>
          <Flex gap='small' vertical>
            <Flex gap='small'>
              <Tooltip placement='topLeft' title='发送邮件使用'>
                <InfoCircleOutlined />
              </Tooltip>
              <span>支付按钮</span>
            </Flex>
            <HtmlPreview value={generatedObject.payhtml} loading={isHtmlLoading} onCopied={() => messageApi.success('已复制')} />
            <Flex gap='small'>
              <Tooltip placement='topLeft' title='发送 WhatsApp 使用'>
                <InfoCircleOutlined />
              </Tooltip>
              <span>支付链接</span>
            <Button
              size='small'
              onClick={() => {
                navigator.clipboard.writeText(generatedObject.paylink)
                messageApi.success('复制成功😀')
              }}>
              复制链接
            </Button>
            </Flex>
            <Typography.Text>{generatedObject.paylink}</Typography.Text>
          </Flex>
        </div>
        {contextHolder}
      </Flex>
    </>
  )
}

export default GeneratePayment
