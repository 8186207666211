import { createContext, useEffect, useState } from 'react'
import { Drawer } from 'antd'
import { useOrderStore } from '@/stores/OrderStore'
import GeneratePayment from '@/views/accounts/GeneratePayment'

const GeneratePaymentDrawer = ({ ...props }) => {
  const [openPaymentDrawer, closePaymentDrawer, paymentDrawerOpen] = useOrderStore((state) => [state.openDrawer, state.closeDrawer, state.drawerOpen])
  return (
    <Drawer title='支付链接' placement={'top'} size={'large'} onClose={() => closePaymentDrawer()} open={paymentDrawerOpen}>
      <GeneratePayment></GeneratePayment>
    </Drawer>
  )
}
export default GeneratePaymentDrawer
