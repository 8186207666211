import { App, Row, Col, Tag, List, Form, Input, Button, Space, Modal, Select, message } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { useState, useEffect } from 'react'
import EditorInput from '../../components/EditorInput'
import HtmlPreview from './HtmlPreview'
import useSnippetStore from '@/stores/SnippetStore'
import useAuthStore from '@/stores/AuthStore'

function SnippetList() {
  const [messageApi, contextHolder] = message.useMessage()

  const [searchform] = Form.useForm()
  const [snippetForm] = Form.useForm()

  const [
    fetchParamList,
    ownerList, typeList, typeAllList, fetchSnippetList, snippetList, fetchSnippetDetail, currentSnippet, saveOrUpdateSnippet,
    deleteCurrentSnippet, createSnippet
  ] = useSnippetStore(s => [
    s.fetchParamList,
    s.ownerList,
    s.typeList,
    s.typeAllList,
    s.fetchSnippetList,
    s.snippetList,
    s.fetchSnippetDetail,
    s.currentSnippet,
    s.saveOrUpdateSnippet,
    s.deleteCurrentSnippet,
    s.createSnippet
  ])

  const [loginUser] = useAuthStore((state) => [state.loginUser])

  const { notification } = App.useApp()
  const [isSnippetModalOpen, setSnippetModalOpen] = useState(false)
  const [isHtmlLoading, setHtmlLoading] = useState(false)

  const onSnippetFinish = (values) => {
    saveOrUpdateSnippet(values)
      .then(() => {
        notification.info({
          message: 'Notification',
          description: '图文保存成功',
          placement: 'top',
        })
        setSnippetModalOpen(false)
      })
      .catch(ex => {
        notification.error({
          message: 'Notification',
          description: ex.message,
          placement: 'top',
          duration: 4,
        })
      })
  }

  const onSnippetFailed = (error) => {
    console.log('Failed:', error)
  }

  const handleSearchFinish = (values) => {
    fetchSnippetList(values)
  }

  const handleSnippetSelected = (snippet) => {
    setHtmlLoading(true)
    fetchSnippetDetail(snippet).finally(() => setHtmlLoading(false))
  }

  const handelSnippetEdit = () => {
    snippetForm.setFieldsValue({opi_sn: loginUser.userId, ...currentSnippet})
    setSnippetModalOpen(true)
  }

  const handelSnippetNew = () => {
    const snippet = createSnippet(loginUser.userId)
    snippetForm.setFieldsValue(snippet)
    setSnippetModalOpen(true)
  }

  const handelSnippetDelete = () => {
    Modal.confirm({
      title: '你确定要删除这条图文吗？',
      icon: <ExclamationCircleFilled />,
      content: currentSnippet.title,
      onOk() {
        deleteCurrentSnippet()
      },
      onCancel() {
        console.log('Cancel')
      },
    })
  }

  useEffect(() => {
    fetchParamList()
  }, [])

  return (
    <>
      <Modal
        centered
        okButtonProps={{
          autoFocus: true,
          htmlType: 'submit',
        }}
        width={800}
        title={'图文'}
        open={isSnippetModalOpen}
        onCancel={() => {
          snippetForm.resetFields()
          setSnippetModalOpen(false)}
        }
        destroyOnClose
        forceRender
        modalRender={(dom) => (
          <Form
            name='snippetForm'
            form={snippetForm}
            layout='vertical'
            className='max-w-4xl'
            initialValues={currentSnippet}
            onFinish={onSnippetFinish}
            onFinishFailed={onSnippetFailed}
            autoComplete='off'>
            {dom}
          </Form>
        )}>
        <Form.Item name='snippetId' className='hidden'>
          <Input />
        </Form.Item>
        <Form.Item name='opi_sn' className='hidden'>
          <Input />
        </Form.Item>
        <Form.Item
          label='标题'
          name='title'
          rules={[
            {
              required: true,
              message: '标题必填',
            },
          ]}>
          <Input />
        </Form.Item>
        <Form.Item
          label='所有者'
          name='owner'
          rules={[
            {
              required: true,
              message: '所有者必选',
            },
          ]}>
          <Select options={ownerList} showSearch optionFilterProp='label' notFoundContent={'找不到'}></Select>
        </Form.Item>
        <Form.Item
          label='类型'
          name='category'
          rules={[
            {
              required: true,
              message: '类型必选',
            },
          ]}>
          <Select options={typeList}></Select>
        </Form.Item>
        <Form.Item
          label='内容'
          name='content'
          rules={[
            {
              required: true,
              message: '内容必填',
            },
          ]}>
          <EditorInput />
        </Form.Item>
      </Modal>

      <Space direction='vertical' size='small' className='w-full h-full overflow-hidden'>
        <Form
          layout={'inline'}
          form={searchform}
          onFinish={handleSearchFinish}
          initialValues={{
            owner: loginUser.userId + '/2', // 默认是登录账号
            type: '',
          }}>
          <Form.Item label='所有者' name='owner'>
            <Select className='!w-40' options={ownerList} showSearch optionFilterProp='label' notFoundContent={'找不到'}></Select>
          </Form.Item>
          <Form.Item label='类别' name='type'>
            <Select className='!w-40' options={typeAllList} />
          </Form.Item>
          <Form.Item label='标题' name='title'>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type='primary' htmlType='submit'>
              搜索
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              onClick={() => {handelSnippetNew()}}>
              新增
            </Button>
          </Form.Item>
        </Form>
        <Row gutter={6} className=' ' style={{height: 'calc(100vh - 196px)'}}>
          <Col span={8} className='h-[inherit] overflow-x-hidden overflow-y-auto'>
            <List
              bordered
              dataSource={snippetList}
              renderItem={(snippet) => (
                <List.Item
                  className='hover:bg-stone-50'
                  onClick={() => {
                    handleSnippetSelected(snippet)
                  }}>
                  <Row gutter={16} className='w-full'>
                    <Col span={20}>{snippet.adi_title}</Col>
                    <Col span={4}>
                      <Tag color='blue'>{snippet.adi_type_name}</Tag>
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          </Col>
          <Col span={16} className='h-[inherit] overflow-y-auto '>
            <HtmlPreview
              value={currentSnippet.content}
              loading={isHtmlLoading}
              onEdit={() => handelSnippetEdit()}
              onCopied={() => messageApi.success('已复制')}
              onDelete={() => handelSnippetDelete()}
            />
          </Col>
          <div></div>
        </Row>
      </Space>
      {contextHolder}
    </>
  )
}

export default SnippetList
