import { fetchJSON, postForm, postJSON } from '@/utils/request'
import { usingStorage } from '@/utils/usingStorage'

const WAI_SERVER_KEY = 'G-STR:WAI_SERVER'

const WAI_API_VER = '/api/v1'

export const postSendMsg = async (body) => {
  const { waiServer } = usingStorage(WAI_SERVER_KEY)
  // const { attaList = [], atta, content, ...bodyData } = body
  // const formData = new FormData()
  // Object.keys(bodyData).forEach(function (key) {
  //   formData.append(key, bodyData[key])
  // })
  // attaList.forEach(function (item) {
  //   formData.append('attachment', item)
  // })
  const { result } = await postJSON(`${waiServer}${WAI_API_VER}/messages/send`, body)
  return result
}

export const fetchQRCode = (phone) => {
  const { waiServer } = usingStorage(WAI_SERVER_KEY)
  return fetchJSON(`${waiServer}${WAI_API_VER}/channels/qrcode`, { phone })
}
