import { Empty, Skeleton, Divider, Flex, Button } from 'antd'
import { Conditional } from '@/components/Conditional'
import { isNotEmpty } from '@/utils/commons'

const HtmlPreview = (props) => {
  const { loading = false, value, onEdit, onCopied, onDelete } = props

  if (loading) {
    return <Skeleton className='p-6' active />
  }

  return (
    <div className='border-solid border rounded border-gray-300'>
      <Conditional
        condition={isNotEmpty(value)}
        whenTrue={
          <div id='__preHtml__' className='whitespace-pre-wrap break-words ps-6 pe-6' dangerouslySetInnerHTML={{__html: value}}>
          </div>
        }
        whenFalse={<Empty className='p-6' description={false} />}
      />
      <Divider className='mt-2 mb-0' />
      <Flex gap='middle' justify='flex-end' wrap className='p-2'>
        <Conditional
          condition={onEdit}
          whenTrue={<Button onClick={onEdit}>编辑</Button>}
        />
        <Conditional
          condition={onCopied}
          whenTrue={
            <Button
              onClick={() => {
                const range = document.createRange()
                range.selectNode(document.getElementById('__preHtml__'))
                window.getSelection().removeAllRanges()
                window.getSelection().addRange(range)
                document.execCommand('copy')
                // window.getSelection().removeAllRanges()
                onCopied()
              }}>
              复制
            </Button>
          }
        />
        <Conditional
          condition={onDelete}
          whenTrue={<Button onClick={onDelete}>删除</Button>}
        />
      </Flex>
    </div>
  )
}

export default HtmlPreview
